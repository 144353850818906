import React, { useEffect, useState, FormEvent, ChangeEvent, useRef } from 'react'
import Select from 'react-select'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import { AiOutlineWarning, AiOutlineWhatsApp } from 'react-icons/ai'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import ModalEdit from '../../../components/ModalDriverEdit'
import { useAuth } from '../../../contexts/auth'
import { Box, Button, Checkbox, Modal, Typography } from '@material-ui/core'
import driverIcon from '../../../assets/images/icons/Motorista-theme.svg'
import caminhaoIcon from '../../../assets/images/icons/truck-theme.svg'
import { CgArrowLongRight } from 'react-icons/cg'
import InputMask from 'react-input-mask'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
  whatsapp: boolean
}

interface iOption {
  label: string
  value: string
}
interface Company {
  id: number
  name: string
}
interface Driver {
  id: number
  name: string
  cpf: string
  cnh: string
  birthDate: string
  contact?: Contact[]
}

interface Brand {
  id: number
  name: string
}
interface Model {
  id: number
  name: string
  truckBrand: Brand
}
interface Authorization {
  hasEmail: boolean
  hasSMS: boolean
  hasWhats: boolean
  id: number
}
interface Truck {
  name: string
  cpf: string
  licensePlate: string
  renavam: string
  owner: string
  id: number
  vehicleModel: Model
  implementType: string
  shaftQuantity: number
  grossCapacity: number
  netCapacity: number
  model?: Model
  authorization: Authorization
}

interface Company {
  id: number
  logo: string
  name: string
  company?: string
  website?: string
  status?: boolean
}

const driverSchema = Yup.object().shape({
  name: Yup.string()
    .max(80, 'Tamanho máximo de 80 caracteres')
    .required('Nome é obrigatório!'),
  cpf: Yup.string()
    .min(14, 'Cpf deve ter 11 caracteres')
    .max(14, 'Cpf deve ter 11 caracteres')
    .required('Número de CPF é obrigatório!'),
  cnh: Yup.string().required('Cnh é obrigatório!'),
  birthDate: Yup.string().required('Data de nascimento é obrigatório!')
})

const NewEditDriver: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState('')
  const [type, setType] = useState('')
  const [modalTruck, setModalTruck] = useState('')
  const [nameModal, setNameModal] = useState('')

  const [shippingOptions, setShippingOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [companyOption, setCompanyOption] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  const [modelOptions, setModelOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  const [shippingValue, setShippingValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  
  const [altCompany, setAltCompany] = useState<any>()

  const [modelValue, setModelValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [brandValue, setBrandValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const [brandTypes, setBrandTypes] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [modalSuspend, setModalSuspend] = useState<any>(false)

  const companyId = localStorage.getItem('@App:companyId')

  const [WhatsAppAtive, setWhatsAppAtive] = useState<boolean>(false)
  const { role, company } = useAuth()

  const [showSecondModal, setShowSecondModal] = useState(false);

  const closeSecondModal = () => {
    setShowSecondModal(false);
  };

  const [queueDate] = useState<string>('')

  const dateQueueFormat = new Date(queueDate)
    .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
    .split(' ')

  const [numberCode, setNumberCode] = useState<string>('')

  const characterToken = numberCode.length

  const [tokenModal, setTokenModal] = useState<string>('')

  const prevCompanyValue = useRef<iOption | null>(null); // Armazena o valor inicial

  const [comp] = useState<Company>()

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      cnh: '',
      birthDate: '',
      isEnabled: false,
      hasEmail: false,
      hasSMS: false,
      hasWhats: false
    },
    validationSchema: driverSchema,
    onSubmit: values => {
      api
        .post('driver/save', {
          id: parseInt(id),
          name: values.name.toUpperCase(),
          cpf: values.cpf,
          cnh: values.cnh,
          shippingCompany: shippingValue.value
            ? { id: parseInt(shippingValue.value) }
            : null,
          birthDate: values.birthDate,
          contact: contacts,
          isEnabled: values.isEnabled,
          company:
            role === 'Administrador'
              ? { id: parseInt(companyValue.value) }
              : { id: company || parseInt(companyId || '') || null }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.goBack()
          }, 2000)
        })
        .catch(e => {
          if (e.response.data.errno === 1062) {
            toast.error('Erro ao cadastrar, estes dados já existem')
          } else if (e?.response?.data?.status === 409) {
            setAltCompany(e?.response?.data?.response?.company_a)
            setModalSuspend(true)
          } else {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          }
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const [contacts, setContacts] = useState<Contact[]>([])
  const [trucks, setTrucks] = useState<Truck[]>([])
  const [contact, setContact] = useState<string>('')
  const [contactFone, setContactFone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [driver, setDriver] = useState<Driver>()
  const [status, setStatus] = useState<Boolean>(true)
  const [idContact, setIdContact] = useState(0)
  const [key, setKey] = useState('')

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  })

  const [inEditModeTruckModel, setInEditTruckModel] = useState({
    status: false,
    rowKey: 0
  })

  const history = useHistory()

  useEffect(() => {
    setNumberCode(tokenGenerate())

    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanyOption(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })
    if (id) {
      api.get(`/driver/${id}`).then(response => {
        if (response.data.company) {
          setCompanyValue({
            value: response.data?.company.id,
            label: `${response.data?.company.id} - ${response.data.company.name}`
          })
        }

        setDriver(response.data)
        setStatus(response.data.isEnable)

        setValues({
          name: response.data.name,
          cpf: response.data.cpf,
          cnh: response.data.cnh,
          birthDate: response.data.birthDate,
          isEnabled: response.data.isEnabled,
          hasSMS: response.data?.authorization[0]?.hasSMS,
          hasWhats: response.data?.authorization[0]?.hasWhats,
          hasEmail: response.data?.authorization[0]?.hasEmail
        })

        setStatus(response.data.isEnabled)
        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          })
        }

        setContacts(response.data.contact)
        setTrucks(response.data.truck)
      })
    }
    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleAddContact() {
    setContacts([
      ...contacts,
      {
        id: 0,
        name: contact,
        fone: contactFone,
        email: contactEmail,
        whatsapp: WhatsAppAtive
      }
    ])
    setContact('')
    setContactFone('')
    setContactEmail('')
    setWhatsAppAtive(false)
  }

  async function handleChangeContact(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContact(event.target.value)
  }

  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16))
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value)
  }

  async function handleEditContact(
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    const value =
      type === 'fone' ? event.target.value.slice(0, 16) : event.target.value

    const newContacts = contacts.map(item => {
      if (item.id === id)
        return {
          id: id,
          name: type === 'name' ? value : item.name,
          fone: type === 'fone' ? value : item.fone,
          email: type === 'email' ? value : item.email,
          whatsapp:
            type === 'whatsapp' ? Boolean(value) : Boolean(item.whatsapp)
        }
      else return item
    })

    setContacts(newContacts)
  }

  function onEditTruck(key: number, truck: Truck) {
    if (!inEditModeTruckModel.status) {
      setInEditTruckModel({
        status: true,
        rowKey: key
      })

      setBrandValue({
        value: String(truck.vehicleModel.truckBrand.id),
        label: truck.vehicleModel.truckBrand.name
      })
      setModelValue({
        value: String(truck.vehicleModel.id),
        label: truck.vehicleModel.name
      })
    } else if (inEditModeTruckModel.rowKey === key) {
      setInEditTruckModel({
        status: false,
        rowKey: key
      })

      api
        .post('driver/save', {
          id: Number(id),
          name: values.name.toUpperCase(),
          cpf: values.cpf,
          cnh: values.cnh,
          shippingCompany: shippingValue.value
            ? { id: parseInt(shippingValue.value) }
            : null,
          birthDate: values.birthDate,
          truck: trucks,
          company:
            role === 'Administrador'
              ? { id: parseInt(companyValue.value) }
              : { id: company || parseInt(companyId || '') || null },
          isEnabled: status
        })
        .then(() => {
          toast.success('Edição realizada com sucesso')
        })
        .catch(e => {
          if (e.response.data.errno === 1062) {
            toast.error('Erro ao cadastrar, estes dados já existem')
          } else {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          }
        })
    }
  }

  function onEdit(key: number) {
    if (!inEditMode.status) {
      setInEditMode({
        status: true,
        rowKey: key
      })
    } else {
      setInEditMode({
        status: false,
        rowKey: key
      })

      api
        .post('driver/save', {
          id: Number(id),
          name: values.name.toUpperCase(),
          cpf: values.cpf,
          cnh: values.cnh,
          shippingCompany: shippingValue.value
            ? { id: parseInt(shippingValue.value) }
            : null,
          birthDate: values.birthDate,
          contact: contacts,
          company:
            role === 'Administrador'
              ? { id: parseInt(companyValue.value) }
              : { id: company || parseInt(companyId || '') || null },
          isEnabled: status
        })
        .then(() => {
          toast.success('Edição realizada com sucesso')
        })
        .catch(e => {
          if (e.response.data.errno === 1062) {
            toast.error('Erro ao cadastrar, estes dados já existem')
          } else {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          }
        })
    }
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCloseModalTruck() {
    hideModalTruck()
  }

  function handleCorfirmAddTruck() {
    showModalTruck()
    setNameModal('sfasf')
  }

  function handleCorfirmDelete(contact: Contact) {
    showModal()
    setType('Contato')
    setNameModal(contact.name)
    setIdContact(contact.id)
    setKey(contact.fone)
  }

  function handleCorfirmDeleteTruck(truck: Truck) {
    showModal()
    setType('Caminhão')
    setNameModal(truck.name)
    setIdContact(truck.id)
    setKey(truck.renavam)
  }

  function handleDelete(id: Number) {
    if (type === 'Contato') {
      handleDeleteContact(Number(id))
    } else {
      handleDeleteTruck(Number(id))
    }
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/contact/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = contacts.filter(item => item.id !== id)
            setContacts(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = contacts.filter(item => item.fone !== key)
      setContacts(results)
    }
  }

  function handleDeleteTruck(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/truck/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = trucks.filter(item => item.id !== id)
            setTrucks(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = contacts.filter(item => item.fone !== key)
      setContacts(results)
    }
  }

  function handleChangeOptionShipping(option: iOption) {
    setShippingValue(option)
  }
  function handleChangeOptionCompany(option: iOption) {
    if (option !== companyValue) {
      setShowSecondModal(true);

      if (companyValue) {
        prevCompanyValue.current = companyValue; // Atualiza prevCompanyValue com o valor anterior
      }
    }
    
    setCompanyValue(option);
  }
  function handleChangeOptionBrand(option: iOption) {
    setBrandValue(option)

    api.get(`/truck-model/brand/${option.value}`).then(response => {
      setModelOptions(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }
  function handleChangeOptionModel(option: iOption, truck: Truck) {
    setModelValue(option)
    truck.vehicleModel.id = parseInt(option.value)
    truck.vehicleModel.name = option.label
    truck.vehicleModel.truckBrand.name = brandValue.label
    truck.vehicleModel.truckBrand.id = parseInt(brandValue.value)
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }
      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return { value: item.id, label: `${item.code} - ${item.name}` }
        })
        setOption(options_)
      })
    }
  }

  function loginTransferedDriver(event: FormEvent) {
    event.preventDefault()
    
    if (numberCode === tokenModal) {
      api
        .post('driver/save', {
          override: true,
          id: driver?.id,
          company: {
            id: companyValue?.value
          }
        })
        .then(() => {
          toast.success('Empresa do mototrista atualizada com sucesso')
          setShowSecondModal(false)
        })
        .catch(e => {
          toast.error('Usuário ou senha inválido')
        })
    } else {
      toast.error('Token inválido')
    }
  }

  function tokenGenerate() {
    const numberCode = Math.floor(Math.random() * 9999) + 1000
    return numberCode.toString()
  }

  function handleTokenChange(event: ChangeEvent<HTMLInputElement>) {
    setTokenModal(event.target.value)
  }


  const showModalTruck = () => {
    setModalTruck('show')
  }
  const hideModalTruck = () => {
    setModalTruck('')
  }

  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  function handleWhatapp() {
    setWhatsAppAtive(!WhatsAppAtive)
  }

  async function handleChangeStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setStatus(event.target.checked)

    values.isEnabled = event.target.checked
  }

  function ReturnSuspendWarning() {
    return (
      <Box className='modal expedition'>
        <Modal
          open={modalSuspend}
          onClose={() => setModalSuspend(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box className=' suspend-warning body driver'>
            <Box className='container'>
              <Box>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  <div className='advice'>
                    <div>
                      <div className='warn-icon'>
                        <AiOutlineWarning />
                      </div>
                      <div className='bold-text'>ATENÇÃO! </div>
                    </div>
                    <div className='modal-content'>
                      <div className='modal-title'>{`Motorista está na fila na empresa ${altCompany}, deseja remover?`}</div>
                      {`Por favor informar ao motorista e expedição responsável pelo atendimento que ao efetuar a mudança o motorista e seu veículo são desassociados de pedidos e fila da empresa atual e devem reiniciar o processo na nova empresa.
                    `}
                    </div>
                  </div>
                </Typography>
              </Box>

              <Box className='actions buttons'>
                <Button
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    api
                      .post('driver/save', {
                        override: true,
                        id: driver?.id,
                        company: {
                          id: companyValue?.value
                        }
                      })
                      .then(() => {
                        toast.success('Sucesso')
                        window.location.reload()
                      })
                      .catch(e => {
                        toast.error('Usuário ou senha inválido')
                      })
                  }}
                >
                  Sim
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={showSecondModal}
          onClose={closeSecondModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <form onSubmit={loginTransferedDriver}>
            <Box className=' suspend-warning body'>
              <Box className='container diver'>
                <Box>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    <div className='transfer advice'>
                      <div className='modal-header'>
                        <div className='bold-text'>
                          <AiOutlineWarning className='warn-icon' />
                          Atenção!
                        </div>
                      </div>
                      <div className='modal-text-title'>
                        O motorista possui cadastro na empresa{' '}
                        <span>{prevCompanyValue.current?.label}</span>, deseja
                        transferir ele para a empresa <span>{companyValue.label}</span>
                        ?
                      </div>
                      <div className='visual-transfer'>
                        <div className='company-logo'>
                          <img
                            src={`${process.env.REACT_APP_API}/${altCompany?.logo}`}
                            alt=''
                          />
                        </div>

                        <div className='mid'>
                          <div className='icons'>
                            <div>
                              {' '}
                              <img alt='' src={driverIcon} />{' '}
                            </div>
                            <div>
                              {' '}
                              <img alt='' src={caminhaoIcon} />{' '}
                            </div>
                          </div>

                          <div className='arrow'>
                            <CgArrowLongRight />
                          </div>
                        </div>

                        <div className='company-logo'>
                          <img
                            src={`${process.env.REACT_APP_API}/${comp?.logo}`}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                </Box>
                <Box className='modal-content'>
                  <div className='modal-text-paragraph'>
                    Ao efetuar a mudança,{' '}
                    <span>
                      motorista e veiculo são automaticamente desassociados
                      da fila da empresa atual e devem reiniciar o processo
                      na nova empresa.
                    </span>
                  </div>
                  {queueDate && (
                    <>
                      <div className='modal-row'></div>
                      <div className='modal-position'>
                        Sua posição na fila {altCompany?.company} com
                        previsão para{' '}
                        <span>
                          {dateQueueFormat[0]} ás {dateQueueFormat[1]}
                        </span>{' '}
                        será{' '}
                        <span className='red'>
                          cancelada automaticamente
                        </span>
                      </div>
                    </>
                  )}
                  <div className='modal-row'></div>
                  <div className='modal-code'>
                    <h2>Está ciente disso e deseja continuar</h2>
                    <div>
                      <p>
                        Digite o código <span>{numberCode}</span> na caixa
                        ao lado
                      </p>
                      <InputMask
                        max={5}
                        min={4}
                        mask={characterToken === 5 ? '99999' : '9999'}
                        id='inputToken'
                        required
                        value={tokenModal}
                        onChange={e => handleTokenChange(e)}
                        type='text'
                      />
                    </div>
                  </div>
                </Box>
                <Box className='actions buttons transfer'>
                  <Button
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    Não
                  </Button>
                  <Button type='submit'>Sim</Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Modal>
      </Box>
    )
  }

  return (
    <div className='new-driver-container'>
      <ModalDelete
        type={type}
        name={nameModal}
        id={idContact}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDelete}
      />
      <ModalEdit
        type='Caminhão'
        name={nameModal}
        driver={driver}
        id={idContact}
        className={modalTruck}
        handleClose={handleCloseModalTruck}
        handleDeleteAction={handleDeleteContact}
      />
      <ReturnSuspendWarning />

      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!id ? ' Cadastrar ' : 'Editar'}`} Motorista{!id ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header'>
            <button onClick={history.goBack} id='btBack' type='button'>
              <MdKeyboardArrowLeft size={20} color='#fff' />
              Voltar
            </button>

            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome completo'
                onChange={handleChange}
                value={values.name}
                label='Nome completo'
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>
            <div className={`input ${errors.cpf ? 'error' : ''}`}>
              <Input
                name='cpf'
                mask='###.###.###-##'
                placeholder='CPF'
                value={values.cpf}
                onChange={handleChange}
                label='CPF'
                maxLength={14}
              />

              {errors.cpf && <div className='validateError'>{errors.cpf}</div>}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.birthDate ? 'error' : ''}`}>
              <Input
                name='birthDate'
                onChange={handleChange}
                type='date'
                value={values.birthDate}
                label='Data de nascimento'
                maxLength={25}
              />
              {errors.birthDate && (
                <div className='validateError'>{errors.birthDate}</div>
              )}
            </div>
            <div className={`input ${errors.cnh ? 'error' : ''}`}>
              <Input
                name='cnh'
                placeholder='CNH'
                value={values.cnh}
                onChange={handleChange}
                label='CNH'
                maxLength={11}
              />

              {errors.cnh && <div className='validateError'>{errors.cnh}</div>}
            </div>
          </div>
        </div>

        {role !== 'Transportadora' && (
          <div className='row'>
            <div className={`input select ${errors.isEnabled ? 'error' : ''}`}>
              <div className='select-shipping' style={{ width: 500 }}>
                <label>Transportadora:</label>
                <Select
                  className='select-options'
                  value={shippingValue}
                  options={shippingOptions}
                  onInputChange={value =>
                    handleChangeInputSelect(
                      value,
                      'shipping-company',
                      setShippingOptions
                    )
                  }
                  onChange={option =>
                    handleChangeOptionShipping(option as iOption)
                  }
                />
              </div>

              {shippingValue && (
                <div className='truck-profile'>
                  <Button
                    className='erase-button'
                    onClick={() => setShippingValue({ value: '', label: '' })}
                  >
                    x
                  </Button>
                </div>
              )}
            </div>

            <div className={`input ${errors.isEnabled ? 'error' : ''}`}>
              <label>Status</label>
              <div>
                <label className='switch'>
                  <input
                    name='status'
                    placeholder='Status'
                    onChange={handleChangeStatus}
                    type='checkbox'
                    {...(status === true
                      ? { checked: true }
                      : { checked: false })}
                  />
                  <div className='slider round'>
                    <span className='on'>Ativo</span>
                    <span className='off'>Inativo</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        )}
        {role === 'Administrador' && (
          <div className='row'>
            <div className={`input select ${errors.isEnabled ? 'error' : ''}`}>
              <div className='select-shipping' style={{ width: 500 }}>
                <label>Empresa:</label>
                <Select
                  className='select-options'
                  value={companyValue}
                  options={companyOption}
                  onChange={option =>
                    handleChangeOptionCompany(option as iOption)
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className={`input checkbox ${errors.isEnabled ? 'error' : ''}`}>
            <fieldset>
              <legend>Contato:</legend>
              <div>
                <Checkbox
                  name='hasSMS'
                  checked={values.hasSMS}
                  value={values.hasSMS}
                  onChange={handleChange}
                  disabled={true}
                  color='default'
                  size='medium'
                />
                <label>Autorizar envio de mensagens sms</label>
              </div>

              <div>
                <Checkbox
                  name='hasWhats'
                  checked={values.hasWhats || WhatsAppAtive}
                  value={values.hasWhats}
                  onChange={handleChange}
                  disabled={true}
                  color='default'
                  size='medium'
                />
                <label>Autorizar mensagens ao whatsapp</label>
              </div>

              <div>
                <Checkbox
                  name='hasEmail'
                  checked={values.hasEmail}
                  value={values.hasEmail}
                  onChange={handleChange}
                  disabled={true}
                  color='default'
                  size='medium'
                />
                <label>Autorizar envio de mensagens ao email</label>
              </div>
            </fieldset>
          </div>
        </div>

        <div className='contacts'>
          <fieldset>
            <legend>Contatos</legend>
            <table>
              <thead>
                <tr>
                  <th>Contato</th>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {contacts.length > 0 ? (
                  contacts.map((contact, index) => (
                    <tr key={index}>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            value={contact.name}
                            onChange={event =>
                              handleEditContact(contact.id, event, 'name')
                            }
                          />
                        ) : (
                          contact.name
                        )}
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <AiOutlineWhatsApp
                          size={18}
                          color={!contact.whatsapp ? 'gray' : 'green'}
                        />
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            mask='(##) # ####-####'
                            value={contact.fone}
                            onChange={event =>
                              handleEditContact(contact.id, event, 'fone')
                            }
                          />
                        ) : (
                          contact.fone
                        )}
                      </td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            value={contact.email}
                            onChange={event =>
                              handleEditContact(contact.id, event, 'email')
                            }
                          />
                        ) : (
                          contact.email
                        )}
                      </td>

                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <>
                            <button
                              type='button'
                              className='actionButton green'
                              onClick={() => onEdit(index)}
                            >
                              Salvar
                            </button>
                            <button
                              type='button'
                              className='actionButton red'
                              onClick={() => {
                                handleCorfirmDelete(contact)
                              }}
                            >
                              excluir
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type='button'
                              className='actionButton blue'
                              onClick={() => onEdit(index)}
                            >
                              Editar
                            </button>
                            <button
                              type='button'
                              className='actionButton red'
                              onClick={() => {
                                handleCorfirmDelete(contact)
                              }}
                            >
                              excluir
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
                <tr className='contact'>
                  <td>
                    <Input
                      name='contato'
                      placeholder='Contato'
                      value={contact}
                      onChange={handleChangeContact}
                      label=''
                      readOnly
                    />
                  </td>
                  <td style={{ display: 'flex', alignItems: 'center' }}>
                    <AiOutlineWhatsApp
                      size={32}
                      color={!WhatsAppAtive ? 'gray' : 'green'}
                      onClick={handleWhatapp}
                    />
                    <Input
                      mask='(##) # ####-####'
                      name='fone'
                      placeholder='Telefone'
                      value={contactFone}
                      onChange={handleChangeContactFone}
                      label=''
                      readOnly
                    />
                  </td>
                  <td>
                    <Input
                      name='email'
                      placeholder='E-mail'
                      value={contactEmail}
                      onChange={handleChangeContactEmail}
                      label=''
                      readOnly
                    />
                  </td>
                  <td>
                    <button
                      type='button'
                      id='addContact'
                      onClick={handleAddContact}
                      {...(!contact || !contactFone || !contactEmail
                        ? { disabled: true }
                        : { disabled: false })}
                    >
                      + Adicionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>

        <div className='trucks'>
          <fieldset>
            <legend>Caminhões</legend>
            <table>
              <thead>
                <tr>
                  <th>Placa</th>
                  <th>Renavam</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {trucks.length > 0 ? (
                  trucks.map((truck, index) => (
                    <tr key={index}>
                      <td>
                        {inEditModeTruckModel.status &&
                        inEditModeTruckModel.rowKey === index ? (
                          <Input
                            value={truck.licensePlate}
                            onChange={event =>
                              handleEditContact(truck.id, event, 'name')
                            }
                          />
                        ) : (
                          truck.licensePlate
                        )}
                      </td>
                      <td style={{ height: '50px' }}>
                        {inEditModeTruckModel.status &&
                        inEditModeTruckModel.rowKey === index ? (
                          <Input
                            mask='(##) # ####-####'
                            value={truck.renavam}
                            onChange={event =>
                              handleEditContact(truck.id, event, 'fone')
                            }
                          />
                        ) : (
                          truck.renavam
                        )}
                      </td>
                      <td>
                        {inEditModeTruckModel.status &&
                        inEditModeTruckModel.rowKey === index ? (
                          <div
                            className='select-shipping'
                            style={{ width: 500 }}
                          >
                            <label>Marca:</label>
                            <Select
                              className='select-options'
                              value={brandValue}
                              options={brandTypes}
                              onChange={option =>
                                handleChangeOptionBrand(option as iOption)
                              }
                            />
                          </div>
                        ) : (
                          truck.vehicleModel?.truckBrand.name
                        )}
                      </td>
                      <td>
                        {inEditModeTruckModel.status &&
                        inEditModeTruckModel.rowKey === index ? (
                          <div
                            className='select-shipping'
                            style={{ width: 500 }}
                          >
                            <label>Modelo:</label>
                            <Select
                              className='select-options'
                              value={modelValue}
                              options={modelOptions}
                              onChange={option =>
                                handleChangeOptionModel(
                                  option as iOption,
                                  truck
                                )
                              }
                            />
                          </div>
                        ) : (
                          truck.vehicleModel?.name
                        )}
                      </td>

                      <td>
                        {inEditModeTruckModel.status &&
                        inEditModeTruckModel.rowKey === index ? (
                          <>
                            <button
                              type='button'
                              className='actionButton green'
                              onClick={() => onEditTruck(index, truck)}
                            >
                              Salvar
                            </button>
                            <button
                              type='button'
                              className='actionButton red'
                              onClick={() => {
                                handleCorfirmDeleteTruck(truck)
                              }}
                            >
                              excluir
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type='button'
                              className='actionButton blue'
                              onClick={() => onEditTruck(index, truck)}
                            >
                              Editar
                            </button>
                            <button
                              type='button'
                              className='actionButton red'
                              onClick={() => {
                                handleCorfirmDeleteTruck(truck)
                              }}
                            >
                              excluir
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
                <tr className='truck'>
                  <td>
                    {/* <Input name="plate" placeholder="Placa"
                      value={truckPlate}
                      onChange={handleChangeContact}
                      label="" 

                    />*/}
                  </td>
                  <td style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Input mask="(##) # ####-####" name="fone" placeholder="Renavam"
                      value={truckRenavam}
                      onChange={handleChangeContactFone}
                      label="" 
                    />*/}
                  </td>
                  <td>
                    {/* <Input name="model" placeholder="Modelo"
                      value={truckModel}
                      onChange={handleChangeContactEmail}
                      label=""

                    /> */}
                  </td>
                  <td>
                    <button
                      className='button-add-truck'
                      type='button'
                      id='addContact'
                      onClick={() => handleCorfirmAddTruck()}
                    >
                      + Adicionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditDriver
